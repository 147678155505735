import Layout from "../components/layout";
import React from "react";
import pistache from "../../static/pistache.jpg";
const NotFound = () => {
  return (
    <Layout>
      <div className="text-center">
        <h1>404 Page not found</h1>
        <p>Meanwhile meet my cutest dog in the world!</p>
        <img
          src={pistache}
          className="w-2/3 mt-2 mx-auto"
          alt="Pistache (my shiba inu)"
        />
      </div>
    </Layout>
  );
};

export default NotFound;
